
var _weixin={
	oauth(appid,path){
		location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid='+appid+'&redirect_uri='+$.url.en(path)+'&response_type=code&scope=snsapi_userinfo&state=team_buy#wechat_redirect';
	},
  jsSDK:function  (params,callback) {
    if (typeof window.WeixinJSBridge === 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', function () { $.wx.onBridgeReady(params,callback) }, false)
      } else if (document.attachEvent) {
        document.attachEvent('WeixinJSBridgeReady', function () { $.wx.onBridgeReady(params,callback) })
        document.attachEvent('onWeixinJSBridgeReady', function () { $.wx.onBridgeReady(params,callback) })
      }
    } else {
      $.wx.onBridgeReady(params,callback)
    }
  },
  onBridgeReady:function  (params,callback) {
    window.WeixinJSBridge.invoke(
      'getBrandWCPayRequest', {
        'appId': params.appid, // 公众号名称，由商户传入
        'timeStamp': params.timeStamp, // 时间戳，自1970年以来的秒数
        'nonceStr': params.nonceStr, // 随机串
        'package': params.package,
        'signType': 'MD5', // 微信签名方式：
        'paySign': params.sign // 微信签名
      },
      function (res) {
        callback && callback(res)
      }
    )
  }
}
export default _weixin;
