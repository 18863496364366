var ui={
	toast : (text)=> {
	   let css =
	     `position: fixed;
	           z-index:9999999;
	           top:50%;
	           left: 50%;
	           background:rgba(0,0,0,0.7);
	           color: #fff;
	           -webkit-transform: translate(-50%,-50%);
	           -ms-transform: translate(-50%,-50%);
	           transform: translate(-50%,-50%);
	           font-size: 16px;
	           padding: 11px 20px;
	           border-radius:8px;
	           transition:all 0.2s;
	           opacity:0;
	         height:auto;`
	   let obj = document.createElement('div');
	   obj.innerHTML = text;
	   obj.style.cssText = css;
	   document.body.appendChild(obj)
	   setTimeout(function() {
	     obj.style.opacity = 1;
	   }, 1);
	   setTimeout(function() {
	     obj.style.opacity = 0;
	
	     setTimeout(function() {
	       document.body.removeChild(obj)
	     }, 500);
	   }, 2000)
	 },
  alert(title,con,callback){
    let html =
      `
         <div>
           <div class="layui-layer-shade" style="z-index:{{zIndex}};background-color: rgba(0, 0, 0,0.3);" event_click="close"></div>
           <div class="layui-layer layui-layer-dialog" style="z-index:{{zIndex}};top: 50%;left: 50%;transform: translate(-50%,-50%);">
             <div class="layui-layer-title">{{Title}}</div>
             <div class="layui-layer-content">{{Con}}</div>
             <span class="layui-layer-setwin">
               <a class="layui-layer-ico layui-layer-close layui-layer-close1"  href="javascript:;" event_click="close"></a>
              </span>
             <div class="layui-layer-btn layui-layer-btn-">
               <a class="layui-layer-btn0" event_click="define">确定</a>
             </div>
           </div>
         </div>
          `;
    if(!top.zIndex)top.zIndex=9999;
    top.zIndex+=2;
    let obj = $($(
      html.replace(/\{\{zIndex\}\}/g,top.zIndex)
      .replace(/\{\{Title\}\}/g,title)
      .replace(/\{\{Con\}\}/g,con)
    ).appendTo(top.document.body));
    obj.get(0).querySelectorAll('[event_click="define"]').forEach((item)=>{
      item.onclick=()=>{
        obj.remove();
        callback && callback();
      }
    })
    obj.get(0).querySelectorAll('[event_click="close"]').forEach((item)=>{
      item.onclick=()=>{
        obj.remove();
      }
    });
  },
  confirm(title,con,callback){
    let html =
      `
         <div>
           <div class="layui-layer-shade" style="z-index:{{zIndex}};background-color: rgba(0, 0, 0,0.3);" event_click="close"></div>
           <div class="layui-layer layui-layer-dialog" style="z-index:{{zIndex}};top: 50%;left: 50%;transform: translate(-50%,-50%);">
             <div class="layui-layer-title">{{Title}}</div>
             <div class="layui-layer-content">{{Con}}</div>
             <span class="layui-layer-setwin">
               <a class="layui-layer-ico layui-layer-close layui-layer-close1"  href="javascript:;" event_click="close"></a>
              </span>
             <div class="layui-layer-btn layui-layer-btn-">
               <a class="layui-layer-btn0" event_click="define">确定</a>
               <a class="layui-layer-btn1" event_click="close">取消</a>
             </div>
           </div>
         </div>
          `;
    if(!top.zIndex)top.zIndex=9999;
    top.zIndex+=2;
    let obj = $($(
      html.replace(/\{\{zIndex\}\}/g,top.zIndex)
      .replace(/\{\{Title\}\}/g,title)
      .replace(/\{\{Con\}\}/g,con)
    ).appendTo(top.document.body));
    obj.get(0).querySelectorAll('[event_click="define"]').forEach((item)=>{
      item.onclick=()=>{
        obj.remove();
        callback && callback();
      }
    })
    obj.get(0).querySelectorAll('[event_click="close"]').forEach((item)=>{
      item.onclick=()=>{
        obj.remove();
      }
    });
  },
  layer:{
    open(path, title, width, height, callback, getcallback){
      let html =
        `
       <div>
         <div class="layui-layer-shade" style="z-index:{{zIndex}};background-color: rgba(0, 0, 0,0.3);" event_click="close"></div>
         <div class="layui-layer layui-layer-dialog" style="z-index:{{zIndex}};top: 50%;left: 50%;transform: translate(-50%,-50%);">
           <div class="layui-layer-title">{{Title}}</div>
           <div class="layui-layer-content">
           <iframe src="{{Path}}" style="width:{{Width}}px;height:{{Height}}px;" scrolling="yes" frameborder="0"></iframe>
           </div>
           <span class="layui-layer-setwin">
             <a class="layui-layer-ico layui-layer-close layui-layer-close1"  href="javascript:;" event_click="close"></a>
            </span>
         </div>
       </div>
        `;
      title = title || '弹出层';
      width = width || 800;
      height=height || 500;
      let funName = 'layer_' + Math.round(Math.random());
      path=path + (path.indexOf('?') > -1 ? '&' : '?') + 'LayerCallBack=' + funName;
      if(!top.zIndex)top.zIndex=9999;
      top.zIndex+=2;
      let obj = $($(
        html.replace(/\{\{zIndex\}\}/g,top.zIndex)
        .replace(/\{\{Title\}\}/g,title)
        .replace(/\{\{Path\}\}/g,path)
        .replace(/\{\{Width\}\}/g,width)
        .replace(/\{\{Height\}\}/g,height)
      ).appendTo(top.document.body));
      top[funName] = function(data, type) {
          if (data == null) {
              if (type == 'close') obj.remove();
              else if (type == 'get') return getcallback && getcallback();
          } else {
              callback && callback(data);
          }
      }
      obj.get(0).querySelectorAll('[event_click="close"]').forEach((item)=>{
        item.onclick=()=>{
          obj.remove();
        }
      });
    }
    , close: function() {
        var fun = $.string.path(location.href)['LayerCallBack'];
        top[fun] && top[fun](null, 'close');
    }
    , get: function() {
        var fun = $.string.path(location.href)['LayerCallBack'];
        return top[fun] && top[fun](null, 'get');
    }
    , set: function(data) {
        var fun = $.string.path(location.href)['LayerCallBack'];
        top[fun] && top[fun](data);
    }
  }
}
export default ui;
