import _array from './array.js'
import _check from './check.js'
import _date from './date.js'
import _number from './number.js'
import _object from './object.js'
import _string from './string.js'
import _ui from './ui.js'
import _data from './data.js'
import _weixin from './weixin.js'
import _url from './url.js'
import _file from './file.js'
import _network from './network.js'
var $={
	json:function(data){
		/*json转字符串*/
		return JSON.stringify(data);
	},
	_offset:function(obj, scrOnOff) {
	    obj=$(obj).get(0);
	    var left = 0, top = 0;
	    var width = obj.offsetWidth, height = obj.offsetHeight;
	    do { left += obj.offsetLeft - (scrOnOff ? obj.scrollLeft : 0), top += obj.offsetTop - (scrOnOff ? obj.scrollTop : 0); } while (obj = obj.offsetParent);
	    return { 'left': left, 'top': top, 'width': width, 'height': height };
	},
	windowInfo:function() {
	    var dc = document.documentElement || document.body;
	    return { client: { width: dc.clientWidth, height: dc.clientHeight }, scroll: { width: dc.scrollWidth, height: dc.scrollHeight, top: dc.scrollTop, left: dc.scrollLeft} };
	},
	client:function() {
	    return $.windowInfo().client;
	}
	,trim:(str)=>{
	  if(str.constructor==Object){
	    for(let key in str){
	      str[key]=$.trim(str[key]);
	    }
	    return str;
	  }
	  return str.replace(/^\s*|\s*$/g,'');
	}
	,toJs: function(jsonText) {
		/*字符串转json*/
	    try {
	        jsonText = jsonText == '' ? '""' : jsonText;
	        return (new Function("return " + jsonText))();
	    } catch (e) {
	        return null;
	    }
	}
	,_param: function(params, connector) {
	    connector = connector || ['=', '&'];
	    var list = [];
	    for (var key in params) {
	        list[list.length] = key + connector[0] +encodeURIComponent(params[key]);
	    }
	    return list.join(connector[1]);
	}
	,error:function(text){
		console.error('[error]:'+text)
	}
	,log:function(text){
		console.log(text);
	}

	,html_encode:function(str){
		/*html编码*/
		/*例：$.html_encode('&<')*/
		/*输出：&amp;&lt;*/
		if(str==null)return str;
		if(str.constructor!=String)return str;
		  var s = "";
		  if (str.length == 0) return "";
		  s = str.replace(/&/g, "&amp;");
		  s = s.replace(/</g, "&lt;");
		  s = s.replace(/>/g, "&gt;");
		  s = s.replace(/ /g, "&nbsp;");
		  s = s.replace(/\'/g, "&#39;");
		  s = s.replace(/\"/g, "&quot;");
		  s = s.replace(/\n/g, "<br>");
	  	return s;
	}
	,html_decode:function(str){
		/*html解码*/
		/*例：$.html_decode('&amp;&lt;')*/
		/*输出：&<*/
		if(str==null)return str;
		if(str.constructor!=String)return str;
	  var s = "";
	  if (str.length == 0) return "";
	  s = str.replace(/\&amp\;/g, "&");
	  s = s.replace(/\&lt\;/g, "<");
	  s = s.replace(/\&gt\;/g, ">");
	  s = s.replace(/\&nbsp\;/g, " ");
	  s = s.replace(/\&\#39\;/g, "\'");
	  s = s.replace(/\&quot\;/g, "\"");
	  s = s.replace(/\<br\>/g, "\n");
	  return s;
	},
	//扩展
	extend:(key,data1,data2)=>{
		for(let _key in data2){
			data1[_key]=data2[_key];
		}
		window[key]=data1;
	}
};
$.array=_array;
$.check= _check;
$.date= _date;
$.number= _number;
$.object= _object;
$.string= _string;
$.ui= _ui;
$.data= _data;
$.weixin=_weixin;
$.url=_url;
$.file=_file;
$.network=_network
export default $;
