var dateClass={
	toDate: function(text) {
	    /*说明：把字符串转换成Date格式 */
	    /*返回结果：Date*/
	    var converted = Date.parse(text);
	    var myDate = new Date(converted);
	    if (isNaN(myDate)) {
	        var arys = text.split(/[^\d]/);
	        myDate = new Date(arys[0], --arys[1], arys[2], arys[3] || null, arys[4] || null, arys[5] || null, arys[6] || null);
	    }
	    return myDate;
	}
    ,format: function(date, pattern) {
        /*说明：日期格式化 */
        if (typeof (date) == 'string') date = dateClass.toDate(date);
        var d = date;
        pattern = pattern || "yyyy-MM-dd";
        var y = d.getFullYear();
        var o = {
            "M": d.getMonth() + 1, //month
            "d": d.getDate(),    //day
            "h": d.getHours(),   //hour
            "m": d.getMinutes(), //minute
            "s": d.getSeconds() //second
        }
        pattern = pattern.replace(/(y+)/ig, function(a, b) { var len = Math.min(4, b.length); return (y + "").substr(4 - len); });
        for (var i in o) {
            pattern = pattern.replace(new RegExp("(" + i + "+)", "g"), function(a, b) { return (o[i] < 10 && b.length > 1) ? "0" + o[i] : o[i] });
        }
        return pattern;
    }
    , info: function(date, interval) {
        /*说明： 取得日期数据信息*/
        /*参数：interval：数据类型( y 年 m月 d日 w星期  h时 n分 s秒)*/
        /*调用方式：dateClass.info("2004/11/12",'w')*/
        /*返回结果：1*/
      	if (typeof (date) == 'string') date = dateClass.toDate(date);
        var myDate = date;
        var Week = ['日', '一', '二', '三', '四', '五', '六'];
        var data = {
            'y': myDate.getFullYear()
            , 'M': myDate.getMonth() + 1
            , 'd': myDate.getDate()
            , 'w': Week[myDate.getDay()]
            //,'ww':myDate.WeekNumOfYear()
            , 'h': myDate.getHours()
            , 'm': myDate.getMinutes()
            , 's': myDate.getSeconds()
        };
        if (typeof interval == 'string' && data[interval]) {
            return data[interval];
        } else if (interval == null) {
            return data;
        }
    }
	, diff: function(date, interval, dateStr) {
	    /*说明：时间差 (参数date减去this的时间差)*/
	    /*参数：interval：格式[y 年 M 月 d日 | h时 | m分 | s秒 t毫秒 ] date：计算时间*/
	    /*返回结果：1*/
	    var dtStart = date.constructor == Date ? date : dateClass.toDate(date);
	    var dtEnd = dateStr.constructor == Date ? dateStr : dateClass.toDate(dateStr);
	    var objInterval = {
	        't': 1
			, 's': 1000
			, 'm': 1000 * 60
			, 'h': 1000 * 60 * 60
			, 'd': 1000 * 60 * 60 * 24
			, 'w': 1000 * 60 * 60 * 24 * 7
	    };
	    try {
	        switch (interval) {
	            case 'M': return (dtEnd.getMonth() + 1) + ((dtEnd.getFullYear() - dtStart.getFullYear()) * 12) - (dtStart.getMonth() + 1);
	            case 'y': return dtEnd.getFullYear() - dtStart.getFullYear();
	            default: return Math.round((dtEnd - dtStart) / objInterval[interval]);
	        }
	    } catch (e) {
	        return e.message;
	    }
	}
	, days: function(date) {
		if (typeof (date) == 'string') date = dateClass.toDate(date);
	    /*说明：取得当前月的天数*/
	    /*参数：无*/
	    /*返回结果：30*/
	    var y = dateClass.info(date, 'y'), m = dateClass.info(date, 'M');
	    return new Date(y, m, 0).getDate();
	}
	, add: function(date, interval, number) {
		if (typeof (date) == 'string') date = dateClass.toDate(date);
	    /*说明：日期计算(在this时间上累加时间)*/
	    /*参数：interval：数据类型( y 年 M月 d日 w星期 q季度  h时 m分 s秒 t毫秒) number累加值*/
	    /*返回结果：Date*/
	    var dtTmp = date;
	    var objInterval = {
	        't': 1
			, 's': 1000
			, 'm': 1000 * 60
			, 'h': 1000 * 60 * 60
			, 'd': 1000 * 60 * 60 * 24
			, 'w': 1000 * 60 * 60 * 24 * 7
	    };
	    switch (interval) {
	        case 'q': return new Date(dtTmp.getFullYear(), (dtTmp.getMonth()) + number * 3, dtTmp.getDate(), dtTmp.getHours(), dtTmp.getMinutes(), dtTmp.getSeconds());
	        case 'M': return new Date(dtTmp.getFullYear(), (dtTmp.getMonth()) + number, dtTmp.getDate(), dtTmp.getHours(), dtTmp.getMinutes(), dtTmp.getSeconds());
	        case 'y': return new Date((dtTmp.getFullYear() + number), dtTmp.getMonth(), dtTmp.getDate(), dtTmp.getHours(), dtTmp.getMinutes(), dtTmp.getSeconds());
	        default: return new Date(Date.parse(dtTmp) + objInterval[interval] * number);
	    }
	}
    , is: function(date1, date2, type) {
	    	if (typeof (date1) == 'string') date1 = dateClass.toDate(date1);
	    	if (typeof (date2) == 'string') date2 = dateClass.toDate(date2);
        var isyear = (date1.getFullYear() == date2.getFullYear());
        var ismonth = (date1.getMonth() == date2.getMonth());
        var isdate = (date1.getDate() == date2.getDate());
        switch (type) {
            case "w": return isyear;
            case "m": return isyear && ismonth;
            case "d": return isyear && ismonth && isdate;
        }
        return isyear && ismonth && isdate;
    }
    , get: function(date, type) {
    		if (typeof (date) == 'string') date = dateClass.toDate(date);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        var d = date.getDate();
        var w = date.getDay();
        var n = w - 6;
        var ds = new Date(y, m, 0).getDate();

        if (type == 'w') {

            var dates = [];
            for (var i = 0; i < 7; i++) {
                dates[dates.length] = new Date(y, m - 1, i + d + n);
            }
        }
        if (type == 'm') {
            for (var i = 1; i <= ds; i++) {
                dates[dates.length] = new Date(y, m - 1, i);
            }
        }
        return dates;
    }
	, calendar: function(date, callBack, length) {
		if (typeof (date) == 'string') date = dateClass.toDate(date);
	    /*生成日历*/
	    var year = dateClass.info(date, 'y'), month = dateClass.info(date, 'M'),day=dateClass.info(date, 'd');
	    var minDate = (new Date(year, month - 1, 1)).getDay();
	    minDate = minDate == 0 ? 7 : minDate;
	    var maxDate = dateClass.days(date);
	    var prevMaxDate = dateClass.days(new Date(year, month - 2, 1))
	    var thisNum = 1, nextNum = 1;
	    var len = maxDate + minDate;
	    len = len + (7 - len % 7);
	    len = length || len;
	    for (var i = 0; i < len; i++) {
	        if (i >= minDate && i <= maxDate + minDate - 1) {
	            var day6 = false;
	            var day0 = false;
	            var thisDay=false;
	            if (new Date(year, month - 1, thisNum).getDay() == 6) {
	                day6 = true;
	            }
	            if (new Date(year, month - 1, thisNum).getDay() == 0) {
	                day0 = true;
	            }
	            if(day==thisNum){
	            	thisDay=true;
	            }
	            callBack.thisTime && callBack.thisTime(thisNum, thisDay,day0, day6,month,year);
	            thisNum += 1;
	        } else if (i > maxDate + minDate - 1) {
	            callBack.nextTime && callBack.nextTime(nextNum,month+1,year);
	            nextNum += 1;
	        } else {
	            callBack.previousTime && callBack.previousTime(prevMaxDate-(minDate-i-1),month-1,year);

	        }
	    }
	}
}
export default dateClass;
