var check={
	notNull: (text) =>{
	  if (text == null) return true;
	  if (text.constructor != String) text += '';
	  return text.trim() != ''
	},
	isContains: (text, subStr)=> {
	  /*字符串内判断是否存在某个字符串 */
	  return text.indexOf(subStr) > -1;
	},
	isRegExp: (text, regExp)=> {
	  /*输入正则验证字符串*/
	  return (new RegExp(regExp)).test(text)
	},
	isEmail: (text)=> {
	  /*邮箱*/
	  return check.isRegExp(text, "^[\\w-]+(\\.[\\w-]+)*@[\\w-]+(\\.[\\w-]+)+$");
	},
	isInt: (text)=> {
	  //整型
	  return check.isRegExp(text, "^(-|\\+)?\\d+$");
	},
	isFloat: (text)=> {
	  //浮点型
	  return check.isRegExp(text, "^(-|\\+)?\\d+(\\.\\d*)?$");
	},
	isFloat2: (text)=> { /*说明：两位小数的正实数*/
	  return check.isRegExp(text, "^[0-9]+(.[0-9]{1,2})?$")
	},
	isDate: (text) =>{
	  /*日期*/
	  var r = text.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
	  if (r == null) return false;
	  var d = new Date(r[1], r[3] - 1, r[4]);
	  return (d.getFullYear() == r[1] && (d.getMonth() + 1) == r[3] && d.getDate() == r[4]);
	},
	isNumber: (text)=> {
	  /*数值*/
	  return check.isRegExp(text, "^\\d*$");
	},
	isPostalCode: (text)=> { /*说明：邮政编码 无说明 */
	  return check.isRegExp(text, "^[a-zA-Z0-9 ]{3,12}$");
	},
	isUrl: (text)=> { /*说明：url  无说明 */
	  return check.isRegExp(text, "^[a-zA-z]+://(\\w+(-\\w+)*)(\\.(\\w+(-\\w+)*))*(\\?\\S*)?$");
	},
	isPhone: (text) =>{ /*说明：手机号*/
	  return check.isRegExp(text, "^((13[0-9])|(14[5|7|9])|(15([0-9]))|(166)|(17([0-9]))|(18[0-9])|(19[8|9]))\\d{8}$");
	},
	isTel: (text) =>{ /*说明：座机号*/
	  return check.isRegExp(text,
	    "(^[0-9]{3,4}\\-[0-9]{7,8}$)|(^[0-9]{7,8}$)|(^\\([0-9]{3,4}\\)[0-9]{7,8}$)|(^0{0,1}1[0-9]{10}$)");
	},
	isVin: (text) =>{ /*说明：Vin码，字母数字17位*/
	  return check.isRegExp(text, "^[A-Za-z0-9]{17}$")
	},
	isChePai: (text)=> { /*说明：车牌*/
	  return check.isRegExp(text, "^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$")
	},
	isEngineNo: (text)=> { /*说明：发动机号，字母数字15-17位*/
	  return check.isRegExp(text, "^[A-Za-z0-9]{15,20}$")
	},
	isBankCardNo: (text)=> { /*说明：银行卡号*/
	  return check.isRegExp(text, "^((\\d{12})|(\\d{16})|(\\d{19}))$")
	},
	isPassword: (text)=> {
	    /*说明：登录密码*/
	    return check.isRegExp(text, "^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9]{6,16}");
	  }
	  //验证数字、字母-
	  ,
	isIdCard: (idCard)=> {
	  return check.isRegExp(idCard, "^[a-zA-Z0-9\-\——]*$");
	}
}
export default check;