var _object={
	//转换成array
	toArray:function(data){
		var list=[];
		for(var key in data){
			var d={name:key,value:data[key]};
			list.push(d);
		}
		return list;
	}
}
export default _object;
