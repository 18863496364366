export default (callback) => {
  var el = document.body;
  if (el.addEventListener) {
    window.addEventListener("online", function() {
      callback('online');
    }, true);
    window.addEventListener("offline", function() {
      callback("offline");
    }, true);
  } else if (el.attachEvent) {
    window.attachEvent("ononline", function() {
      callback("online");
    });
    window.attachEvent("onoffline", function() {
      callback("offline");
    });
  } else {
    window.ononline = function() {
      callback("online");
    };
    window.onoffline = function() {
      callback("offline");
    };
  }
}
