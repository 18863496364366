import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
  path: "/",
  component: () => import('@/views/layout/index.vue'),
  children: [{
    path: '',
    name: 'Index',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/index/index.vue')
  }, {
    path: '/manager/course/list',
    name: 'managerCourse',
    meta: {
      title: '课程管理'
    },
    component: () => import('@/views/index/manager/course/list.vue')
  }, {
    path: '/manager/course/comment',
    name: 'managerComment',
    meta: {
      title: '课程评论'
    },
    component: () => import('@/views/index/manager/course/comment.vue')
  }, {
    path: '/manager/tea/comment',
    name: 'managerComment',
    meta: {
      title: '老师评论'
    },
    component: () => import('@/views/index/manager/tea/comment.vue')
  }, {
    path: '/manager/course/time',
    name: 'managerTime',
    meta: {
      title: '课时管理'
    },
    component: () => import('@/views/index/manager/course/time.vue')
  }, {
    path: '/manager/course/detail',
    name: 'courseDetail',
    meta: {
      title: '课程详情'
    },
    component: () => import('@/views/index/manager/course/detail.vue')
  }, {
    path: '/manager/test/list',
    name: 'managerTestList',
    meta: {
      title: '考试阅卷'
    },
    component: () => import('@/views/index/manager/test/list.vue')
  }, {
    path: '/manager/test/detail',
    name: 'managerTestDetail',
    meta: {
      title: '考试详情'
    },
    component: () => import('@/views/index/manager/test/detail.vue')
  }, {
    path: '/manager/test/check',
    name: 'managerTestCheck',
    meta: {
      title: '考试阅卷'
    },
    component: () => import('@/views/index/manager/test/check.vue')
  }, {
    path: '/manager/test/result',
    name: 'managerTestResult',
    meta: {
      title: '考试详情'
    },
    component: () => import('@/views/index/manager/test/result.vue')
  }, {
    path: '/my',
    name: 'myInfo',
    meta: {
      title: '个人信息'
    },
    component: () => import('@/views/index/my/index.vue')
  }, {
    path: '/my/edit',
    name: 'myInfo',
    meta: {
      title: '信息编辑'
    },
    component: () => import('@/views/index/my/edit.vue')
  }, {
    path: '/my/password',
    name: 'myInfo',
    meta: {
      title: '修改密码'
    },
    component: () => import('@/views/index/my/password.vue')
  }, {
    path: '/message',
    name: 'Message',
    meta: {
      title: '消息中心'
    },
    component: () => import('@/views/index/message.vue')
  }, {
    path: '/trends',
    name: 'Trends',
    meta: {
      title: '学员趋势'
    },
    component: () => import('@/views/index/trends.vue')
  }]
}, {
  path: '/login',
  name: 'Login',
  meta: {
    title: '登录'
  },
  component: () => import('@/views/login/index.vue')
}, {
  path: '/find/password',
  name: 'FindPassword',
  meta: {
    title: '找回密码'
  },
  component: () => import('@/views/login/find-password.vue')
}];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
