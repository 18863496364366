var file={
  //base64转file
	base64ToFile(base64Data, filename,type) {
      let js={
        dataURLtoBlob: function(dataurl) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        },
        //将blob转换为file
        blobToFile: function(theBlob, fileName){
           theBlob.lastModifiedDate = new Date();
           theBlob.name = fileName;
           return theBlob;
        }
      }
      let blob = js.dataURLtoBlob(base64Data);
      let aasfile = js.blobToFile(blob, filename);
      var aafile = new File([aasfile],filename, { type: type });
      return aafile
  },
  base64(path,_callback,width){
    var img = new Image();
    img.src = path;
    width=width || 800;//100  你想压缩到多大，改这里
    img.onload = function () {
      var that = this;
      var w = that.width,
          h = that.height,
      scale = w / h;
      if(w>width){
          w = width || w;
          h = w / scale;
      }
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      ctx.drawImage(that, 0, 0, w, h);
      $(canvas).attr({width : w, height : h});
      ctx.drawImage(that, 0, 0, w, h);
      var _base64 = canvas.toDataURL('image/jpeg', 0.8 );   //1最清晰，越低越模糊。
      _callback &&_callback(_base64);

    }

  },
  download(Url,callback){
    var blob=new Blob([''], {type:'application/octet-stream'});
    var url = URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = Url;
    a.download = Url.split('?')[0].replace(/(.*\/)*([^.]+.*)/ig,'$2');
    a.setAttribute("target", '_blank');
    var e = document.createEvent('MouseEvents');
    e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    a.dispatchEvent(e);
    URL.revokeObjectURL(url);
    //setTimeout(()=>{
      callback && callback()
    //},100)
  },
  downloads(list){
    let cyc=(i,list)=>{
      if(i>=list.length)return ;
      let item=list[i];
      file.download(item,()=>{
        i+=1;
        cyc(i,list);
      })
    }
    cyc(0,list);
  },
  downloadIamge(imgsrc,callback) {//下载图片
    let image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute("crossOrigin", "Anonymous");
    image.onload = function() {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);
      let url = canvas.toDataURL('image/png',1); //得到图片的base64编码数据
      let a = document.createElement("a"); // 生成一个a元素
      let event = new MouseEvent("click"); // 创建一个单击事件
      a.download = imgsrc.split('?')[0].replace(/(.*\/)*([^.]+.*)/ig,'$2'); // 设置图片名称
      a.href = url; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
      image.remove();
      canvas.remove();
      a.remove();
      setTimeout(()=>{
        callback && callback()
      },1000)

    };
    image.src = imgsrc;
  },
  photo(callback,width){
    width = width || 750;alert(1)
    var obj=$('<input type="file" onchange="window.photoChange(this)" capture="camera" accept="image/*" style="display: none;">').appendTo(document.body);
    window.photoChange=function(ths){
      console.log(ths);
      var _file = ths.files[0];
      var reader = new FileReader();
        reader.onload = function() {
          var result = this.result;
          file.base64(result,function(base64){
          callback(base64,_file.type.split('/')[1],_file);
          $(obj).remove();
        },width)
        }
        reader.readAsDataURL(_file);
    }
    obj.click();
  },
  upload(callback,width){
    let funName='fileChange_'+Math.round(Math.random()*10000)
    width = width || 1000;
    var obj=$('<input  type="file" onchange="window.'+funName+'(this)" multiple accept="image/*" style="display:none"/>').appendTo(document.body);

    window[funName]=function(ths){
      var _file = ths.files[0];
      var reader = new FileReader();
        reader.onload = function() {
          var result = this.result;
          //callback(null,_file.type.split('/')[1],_file);
          //$(obj).remove();
          //return;
          file.base64(result,function(base64){
            let fileName=_file.type.split('/')[1];
            callback(base64,_file.type.split('/')[1],file.convertBase64UrlToBlob(base64,_file.name),_file);
            $(obj).remove();
          },width)
        }
        reader.readAsDataURL(_file);
    }
    obj.click();
  },
  base64ToFile2(_base64,callback){
    file.compress(_base64,1000,0.8).then(function (val) {
      let fileName='1.jpg';
      callback(null,'jpg',file.convertBase64UrlToBlob(val,fileName));
    });
  },
  compress(base64String, w, quality) {
      var getMimeType = function (urlData) {
          var arr = urlData.split(',');
          var mime = arr[0].match(/:(.*?);/)[1];
          // return mime.replace("image/", "");
          return mime;
      };
      var newImage = new Image();
      var imgWidth, imgHeight;

      var promise = new Promise(resolve => newImage.onload = resolve);
      newImage.src = base64String;
      return promise.then(() => {
          imgWidth = newImage.width;
          imgHeight = newImage.height;
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          if (Math.max(imgWidth, imgHeight) > w) {
              if (imgWidth > imgHeight) {
                  canvas.width = w;
                  canvas.height = w * imgHeight / imgWidth;
              } else {
                  canvas.height = w;
                  canvas.width = w * imgWidth / imgHeight;
              }
          } else {
              canvas.width = imgWidth;
              canvas.height = imgHeight;
          }
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(newImage, 0, 0, canvas.width, canvas.height);
          var base64 = canvas.toDataURL(getMimeType(base64String), quality);
          return base64;
      });
  },
  convertBase64UrlToBlob:function(dataurl,filename){

      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
  }
}
export default file;
