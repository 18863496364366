import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from 'axios'
import _$ from './helpers/base.js'
import _vueBase from './helpers/vue/base.js'
import 'element-ui/lib/theme-chalk/index.css'
import '@/common/index.css'
import * as echarts from 'echarts';
import {
  Rate,
  DatePicker,
  Badge,
  Carousel,
  CarouselItem,
  Image,
  Breadcrumb,
  BreadcrumbItem,
  Icon,
  Pagination,
  Form,
  FormItem,
  Select,
  Radio,
  RadioGroup,
  Option,
  Checkbox,
  CheckboxGroup,
  Cascader,
  Upload,
  Dialog,
  Button,
  Input,
  Switch,
  Message,
  MessageBox,
  Collapse,
  CollapseItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Slider,
  Table,
  TableColumn,
  Tabs,
  TabPane,
} from 'element-ui'
//引用富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
//引入富文本css
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
//富文本编辑器添加实例
Vue.use(VueQuillEditor, /* { default global options } */ )
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$echarts = echarts;

_vueBase.extend(Vue, {
  $: _$
})
_vueBase.extend(Vue, _vueBase) // vue扩展

Vue.config.productionTip = false
Vue.prototype.$axios = axios
axios.defaults.baseURL ='http://teacher.100niu.com/api'//'/';// 
// axios.defaults.baseURL ='http://127.0.0.1:8080'//'/';// 
axios.defaults.withCredentials = true
Vue.prototype.baseURL = 'http://teacher.100niu.com/api'//process.env.BASE_API + '/'
// Vue.prototype.baseURL = 'http://127.0.0.1:8080'//process.env.BASE_API + '/'

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})

//Vue.prototype.baseURL = process.env.BASE_API + '/'

Vue.use(Button)
Vue.use(Badge)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Image)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Icon)
Vue.use(Pagination)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Select)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Option)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(DatePicker)
Vue.use(Cascader)
Vue.use(Rate)
Vue.use(Dialog)
Vue.use(Button)
Vue.use(Input)
Vue.use(Switch)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Slider)
Vue.use(Upload)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tabs)
Vue.use(TabPane)

window.vueObj = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
