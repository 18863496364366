import $ from './base.js';
var _data={
	get(key){
		var storage=window.localStorage;
		return $.toJs(storage[key]);
	},
	set(key,data){
		var storage=window.localStorage;
		storage.setItem(key,$.json(data));
	}
}
export default _data;
