var string={
	path: function(text) {
	    /*解析路径参数*/
      console.log(text)
      let _text=text;
      if(_text.indexOf('#')>-1){
        _text=_text.split('#')[1];
        if(text.indexOf('?')>-1 && _text.indexOf('?')==-1){
          _text=text;
        }else{
          text=_text;
        }
      }
      let sps=text.split('?');
	    var parameter = sps[1];
	    if (!parameter) { return null; }
	    var parameterHash = {}, parameter = '&' + parameter;
	    parameter.replace(/&([^=]+)=([^&]*)/g, function() {
	        parameterHash[arguments[1]] = arguments[2]
	    })
	    return parameterHash;
	}
	, clearHtml: function(text) {
	    return text.replace(/<[^>]+>/g, ""); //去掉所有的html标记
	}
}
export default string;
