import Vue from 'vue'
import axios from 'axios'
import eventHref from '../eventhref.js'
import formurlencoded from 'form-urlencoded';
import $ from '../base.js';

axios.interceptors.response.use(
  response => {
    console.log(response)
    if (response.data.code == '601') {
      console.log('123')
      window.vueObj.$router.push('/login')
      return response;
    }
    return response;
  },
  error => {
    console.error(error);
    return Promise.reject(error);
  },
);

//下拉刷新
Vue.directive('drop-down', {
  inserted: function(el, binding) {
    let datas = {
      s: {
        x: 0,
        y: 0
      },
      e: {
        x: 0,
        y: 0
      }
    };
    let _e = null;
    el.ontouchstart = (e) => {
      datas.s.x = e.touches[0].screenX;
      datas.s.y = e.touches[0].screenY;
      _e = e;
    }
    let isInTow = false;
    el.ontouchmove = (e) => {
      datas.e.x = e.touches[0].screenX;
      datas.e.y = e.touches[0].screenY;
      if (el.scrollTop == 0 && datas.e.y > datas.s.y) {
        isInTow = true;
        binding.value.callback(datas.e.y - datas.s.y);
      }
    }
    el.ontouchend = () => {

      if (el.scrollTop == 0) {
        let yc = datas.e.y - datas.s.y;
        if (yc > 50 && datas.e.y != 0) {
          binding.value.post('up')
        } else {
          binding.value.post(0)
        }
      }
      datas = {
        s: {
          x: 0,
          y: 0
        },
        e: {
          x: 0,
          y: 0
        }
      };

    }
    el.onscroll = () => {
      if (el.scrollTop >= el.scrollHeight - el.offsetHeight - 100) {
        binding.value.post('down')
      }
    }
  }
});
//手势扩展类
Vue.directive('gesture', {
  inserted: function(el, binding) {
    let datas = {
      s: {
        x: 0,
        y: 0,
        time: new Date()
      },
      e: {
        x: 0,
        y: 0,
        time: new Date()
      }
    };
    let _e = null;
    el.ontouchstart = (e) => {
      datas.s.x = e.touches[0].screenX;
      datas.s.y = e.touches[0].screenY;
      datas.s.time = new Date();
      _e = e;
    }
    el.ontouchmove = (e) => {
      datas.e.x = e.touches[0].screenX;
      datas.e.y = e.touches[0].screenY;
      datas.e.time = new Date();
    }
    el.ontouchend = () => {
      let xc = datas.e.x - datas.s.x;
      if (datas.e.time.getTime() - datas.s.time.getTime() < 1) return;
      if (xc > 100) {
        binding.value.callback(1, _e)
      }
      if (xc < -100) {
        binding.value.callback(2, _e)
      }
    }
  }
});

Vue.directive('scroll', {
  inserted: function(el, binding) {
    // console.log(binding)
    let arg = binding.arg
    let cleanOutSelect = function() {
      try {
        document.selection.empty();
      } catch (exp) {
        try {
          window.getSelection().removeAllRanges();
        } catch (exp) {}
      }
    };
    if (binding.value.value) {
      let length = el.children[0].offsetHeight;

      if (arg == 'top') {
        el.scrollTop = binding.value.value * length;
      }
      if (arg == 'left') {
        el.scrollLeft = binding.value.value * length;
      }
    }
    el.ontouchstart = function(ev) {
      let length = el.children[0].offsetHeight;

      let et = ev.changedTouches[0];
      let disX = et.clientX - el.offsetLeft
      let disY = et.clientY - el.offsetTop
      let thisTop = el.scrollTop
      let thisLeft = el.scrollLeft

      document.ontouchmove = function(ev) {

        let et = ev.changedTouches[0];
        let l = et.clientX - disX
        let t = et.clientY - disY

        if (arg == 'top') {
          el.scrollTop = thisTop - t
        }
        if (arg == 'left') {
          el.scrollLeft = thisLeft - l
        }
      }
      document.ontouchend = function() {
        if (arg == 'top') {
          let mo = el.scrollTop % length;
          let top = 0;
          if (mo > length / 2) top = el.scrollTop - el.scrollTop % length + length;
          else top = el.scrollTop - el.scrollTop % length;
          el.scrollTop = top;
          //console.log(top)
          //console.log(mo,el.scrollTop , length)
          binding.value.callback(top / length, binding.value)
        }
        if (arg == 'left') {
          let mo = el.scrollLeft % length
          if (mo > length / 2) el.scrollLeft = el.scrollLeft - el.scrollLeft % length + length
          else el.scrollLeft = el.scrollLeft - el.scrollLeft % length
          binding.value.callback(el.scrollLeft / length)
        }
        document.ontouchmove = null
        document.ontouchend = null
      }
    }
  }

})
//数据缓存
let storage = {
  get(key) {
    return $.toJs(localStorage.getItem(key));
  },
  set(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
  },
  remove(key) {
    localStorage.removeItem(key);
  }
}

var base = {
  verify: {
    init(id, attr, page) {
      page.$refs[id].querySelectorAll('[verify]').forEach((item) => {
        let verifyData = $.toJs(item.getAttribute('verify').replace(/'/g, '"'));
        if (!page.$refs[id].verifyData) page.$refs[id].verifyData = {};
        if (!page.$refs[id].verifyData[id]) page.$refs[id].verifyData[id] = {};
        page.$refs[id].verifyData[id][verifyData[0]] = verifyData[1];
        this.write(item, verifyData[0]);
        if (!page.watch) page.watch = {};
        page.$watch(attr + '.' + verifyData[0], (nVal) => {
          //console.log(nVal)
          this.validateAttr(id, attr, page, verifyData[0], page.$refs[id].verifyData[id])
        })
      })
    },
    write(item, attr) {
      let div = document.createElement('div');
      div.className = 'verify-err';
      div.style.display = 'none';
      div.setAttribute('verify-err', attr);
      item.appendChild(div);
    },
    validateAttr(id, attr, page, key, vData) {
      let value = page[attr][key];
      let error = '';
      let isOn = true;
      for (let i = 0; i < vData[key].rules.length; i++) {
        let v = vData[key].rules[i];
        if (v.required) {
          if (value === null || value === '') {
            isOn = false;
            error = v.message;
            break;
          }
        }
        if (v.type) {
          if (v.type == 'int') {
            if (!$.check.isInt(value)) {
              isOn = false;
              error = v.message;
              break;
            }
          }
          if (v.type == 'float') {
            if (!$.check.isFloat(value)) {
              isOn = false;
              error = v.message;
              break;
            }
          }
          if (v.type == 'time') {
            if (!$.check.isDate(value)) {
              isOn = false;
              error = v.message;
              break;
            }
          }
          if (v.type == 'email') {
            if (!$.check.isEmail(value)) {
              isOn = false;
              error = v.message;
              break;
            }
          }
          if (v.type == 'phone') {
            if (!$.check.isPhone(value)) {
              isOn = false;
              error = v.message;
              break;
            }
          }
        }
      }
      let err = page.$refs[id].querySelector('[verify-err="' + key + '"]')
      if (error != '') {
        err.innerHTML = error;
        err.style.display = 'block'
      } else {
        err.innerHTML = '';
        err.style.display = 'none'
      }
      return isOn;
    },
    validate(id, attr, page) {
      let isOn = true;
      if (!page.$refs[id].verifyData) return true;
      let vData = page.$refs[id].verifyData[id];
      for (let key in vData) {
        if (!this.validateAttr(id, attr, page, key, vData)) {
          isOn = false;
        }
      }
      return isOn;
    }
  },
  //执行post请求
  post: (url, data, callback, hkey, htime, type) => {
    //alert(axios.defaults.headers.common['Authorization']);
    const userToken = $.data.get('Token')
	if(userToken){
		axios.defaults.headers = {
		  'Authorization': 'Bearer ' + userToken
		}
	}
    
    let defaultBranch = storage.get('defaultBranch');
    if (defaultBranch) {
      data.branchId = defaultBranch.branchId;
    }
    if (hkey) {
      let d = storage.get(hkey);
      if (d) {
        if (htime && d.time) {
          let diffTime = $.date.diff(new Date(d.time), 'm', new Date());

          if (diffTime <= 30) {
            callback(base.returnData(d.data, type))
            return;
          }
        } else {
          callback(base.returnData(d.data, type))
          return;
        }
      }
    }
    let token = $.data.get('token');
    if (token) {
      data.token = token;
    }
    //console.log(url)
    axios.post(url, data)
      .then(response => {
        //console.log('post response',response)
        callback(base.returnData(response.data, type))
        if (hkey) {
          if (response.data.code == '0000') {
            storage.set(hkey, {
              data: response.data,
              time: new Date()
            })
          }
        }
      });

  },
  postAll(funs) {
    axios.all(funs).then(responseList => {
      callback && callback(responseList.map((response) => {
        return response.data
      }))
    });
  },
  postFile(url, file, callback) {
    let token = $.data.get('token');

    const formData = new FormData();
    formData.append('file', file);
    if (token) {
      formData.append('token', token);
    }
    axios.post(url, formData, {
        transformRequest: [
          (data, headers) => {
            headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
            return data;
          },
        ],
      })
      .then(response => {
        callback && callback(response.data)
      });
  },
  returnData: (data, type) => {
    if (type != null) {
      let d = {};
      data[type['field']].forEach((item) => {
        d[item[type['key']]] = item
      })
      return d;
    } else {
      return data;
    }
  },
  //执行get请求
  get: (url, data, callback, hkey, htime, type) => {
    const userToken = $.data.get('Token')
	if(userToken){
		axios.defaults.headers = {
		  'Authorization': 'Bearer ' + userToken
		}
	}
    
    // axios.defaults.headers = {
    //   'Authorization': 'Bearer 2b43b0a6-26be-4295-a411-b02e196a1690'
    // }
    let defaultBranch = storage.get('defaultBranch');
    if (defaultBranch) {
      data.branchId = defaultBranch.branchId;
    }
    if (hkey) {
      let d = storage.get(hkey);
      if (d) {
        if (htime && d.time) {
          let diffTime = $.date.diff(new Date(d.time), 'm', new Date());
          if (diffTime <= 30) {
            callback(base.returnData(d.data, type))
            return;
          }
        } else {
          callback(base.returnData(d.data, type))
          return;
        }
      }
    }
    let token = $.data.get('token');
    if (token) {
      data.token = token;
    }
    axios.get(url, {
        params: data
      })
      .then(response => {
        //console.log(url)
        //console.log('get response',response)
        callback && callback(base.returnData(response.data, type))
        if (hkey) {
          if (response.data.code == '0000') {
            storage.set(hkey, {
              data: response.data,
              time: new Date()
            })
          }
        }
      });
  },
  //获取标签数据
  getData: function(e) {
    return e.currentTarget.dataset;
  },
  //跳转页
  href: function(e, data, callback) {
    let path = '';
    let _data = {};
    if (e.constructor == String) {
      path = e;
      _data = data;
    } else {
      let data = this.getData(e);
      path = data.path;
      for (let key in data) {
        if (key != 'path') _data[key] = data[key];
      }
    }
    let attr = $._param(_data);
    if (attr != '') {
      path += (path.indexOf('?') > -1 ? '&' : '?') + attr
    }

    if (callback) {
      eventHref.$on('pageCallBack', (data) => {
        callback(data);
        eventHref.$off('pageCallBack')
      });

    }

    this.$router.push({
      path: path
    })
  },
  //返回上一页
  back: function(data) {
    if (data) {
      eventHref.$emit('pageCallBack', data);
    }
    this.$router.go(-1);
  },
  //扩展
  extend: (data1, data2) => {
    for (let _key in data2) {
      data1.prototype[_key] = data2[_key];
    }
  }
}
export default base;
