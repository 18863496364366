var array={
	//链式处理数组查询、重组对象、返回结果
	chain:function(callback){
		return new chainClass(callback);
	}
	,tree:function(list,pid,attr){
		var newlist = [];
	    for (var i = 0; i < list.length; i++) {
	        if (list[i][attr.pid] == pid) {
	            var data = list[i];
	            data[attr.sub]=this.tree(list, data[attr.id],attr)
	            newlist.push(data);
	        }
	    }
	    if (newlist.length == 0) return null;
	    return newlist;
	}
}
export default array;
//循环
	Array.prototype.ForEach=function(fun = (item,key)=>{return item}){
		for(var i=0;i<this.length;i++){
			if(fun(this[i],i)==true)break;
		}
		return this;
	}
	//条件语句
	Array.prototype.Where=function(fun = (item,key)=>{return true}){
		var newList=[];
		this.ForEach((item,key)=>{
			if(fun(item,key)){
				newList.push(item);
			}
		});
		return newList;
	}
	//获取第一条
	Array.prototype.FirstOrDefault=function(fun = (item,key)=>{return true}){
		var newList=this.Where(fun);
		return newList[0];
	}
	//获取最后一条
	Array.prototype.LastOrDefault=function(fun = (item,key)=>{return true}){
		var newList=this.Where(fun);
		return newList[newList.length-1];
	}
	//多数组合并
	Array.prototype.Join=function(l=[],fun=(a,b)=>{return true},end=(a,b)=>{
		if(b){
			for(var key in b)a[key]=b[key];
		}
		return a;
	}){
		var newList=[];
		this.ForEach((a)=>{
			var _b=[];
			l.ForEach((b)=>{
				if(fun(a,b)){
					_b.push(b);
				}
			});
			a=end(a,_b);
			newList.push(a);
		});
		return newList;
	}
	//选择输出字段
	Array.prototype.Select=function(fun = (item,key)=>{return item}){
		var newList=[];
		this.ForEach((item,key)=>{
			newList.push(fun(item,key));
		});
		return newList;
	}
	//排序
	Array.prototype.OrderBy=function(items){
		var by=(list,{field=null,type='asc'})=>{
			var _fun=(x,y)=>{
				if (type == 'asc') {
		            if (field == null) return x > y;
		            else return x[field] > y[field];
		        } else if(type=='desc') {
		            if (field == null) return x < y;
		            else return x[field] < y[field];
		        }
			}
		    var len = list.length;
		    for (var j = len - 1; j > 0; j--) {
		        for (var i = 0; i < j; i++) {
		            if (_fun(list[i], list[i + 1])) {
		                var temp = list[i];
		                list[i] = list[i + 1];
		                list[i + 1] = temp;
		            }
		        }
		    }
		    return list;
		}
		var run=(key,items,list)=>{
			if(key>items.length-1)return list;
			var item=items[key];
			var newList=by(list,item);
			var newData={};
			var newDatas=[];
			newList.ForEach((_item)=>{
				if(!newData[_item[item.field]]){
					newData[_item[item.field]]=[];
					newDatas.push(_item[item.field]);
				}
				newData[_item[item.field]].push(_item);
			});
			var newListEnd=[];
			newDatas.ForEach((_item,k)=>{
				var d=run(key+1,items,newData[_item]);
				newListEnd=newListEnd.concat(d);
			});
			return newListEnd;
		}
		
		var d=run(0,items,this);
		
		return d;
	}
	//组合查询
	Array.prototype.GroupBy=function(fields=[],fun=(item)=>{return item;}){
		var list=this;
		var datas={};
		var dataList=[];
		list.ForEach((item,i)=>{
			var names=[];
			fields.ForEach((f,k)=>{
				names.push(item[f]);
			});
			var name='GroupBy-'+names.join('-');
			if(!datas[name]){
				datas[name]=[];
				dataList.push(name);
			}
			datas[name].push(item);
		});
		var newList=[];
		dataList.ForEach((name,k)=>{
			newList.push(fun(datas[name]));
		});
		return newList;
	}
/*
 * 链式处理数组查询、重组对象、返回结果
 * Start：起始项
 * Find：查询项
 * Select：输出项
 * End：结束项
 * */
var chainClass=function(callback){
	this.list=[];
	this.runList=[];
	if(callback)this.Start(callback);
}
chainClass.prototype={
	Start:function(callback){
		var ths=this;
		callback(function(list){
			ths.list=list;
			ths.run();
		});
		return this;
	}
	,run:function(){
		if(this.runList.length==0)return;
		var item=this.runList.shift();
		if(!item.callback)item.callback=function(e){return e};
		if(item.type=='find'){
			this.cyc(function(d){
				var is= item.callback(d);
				if(!is)return null;
				return d;
			});
		}
		if(item.type=='select'){
			this.cyc(function(d){
				return item.callback(d);
			});
		}
		if(item.type=='end'){
			item.callback(this.list);
		}
		this.run();
	}
	,cyc:function(callback){
		var newList=[];
		for(var i=0;i<this.list.length;i++){
			var d=callback(this.list[i]);
			if(d)newList.push(d);
		}
		this.list=newList;
	}
	,Find:function(callback){
		this.runList.push({
			type:'find'
			,callback:callback
		});
		return this;
	}
	,Select:function(callback){
		this.runList.push({
			type:'select'
			,callback:callback
		});
		return this;
	}
	,End:function(callback){
		this.runList.push({
			type:'end'
			,callback:callback
		});
		return this;
	}
}
